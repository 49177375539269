<template>
  <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
    <v-form lazy-validation @submit.prevent="handleSubmit(save)">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" hidden md="6" sm="12">
              <v-text-field v-model="editedItem.id" :label="$t('clientsPage.id')"></v-text-field>
            </v-col>

            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider rules="required|max:200" v-slot="{ errors }" name="name">
                <v-text-field
                  v-model="editedItem.name"
                  name="name"
                  clearable
                  counter="200"
                  :label="$t('clientsPage.name')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider rules="max:200" v-slot="{ errors }" name="address">
                <v-text-field
                  v-model="editedItem.address"
                  name="address"
                  clearable
                  counter="200"
                  :label="$t('clientsPage.address')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider
                :rules="{
                  max: 200,
                  required: true,
                  regex: /^\+?[0-9\s\-()]+$/,
                }"
                v-slot="{ errors }"
                name="phone_number">
                <v-text-field
                  v-model="editedItem.phone"
                  name="phone"
                  clearable
                  counter="200"
                  :label="$t('clientsPage.phoneNumber')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider rules="required|email|max:200" v-slot="{ errors }" name="email">
                <v-text-field
                  v-model="editedItem.email"
                  name="email"
                  clearable
                  counter="200"
                  :label="$t('clientsPage.email')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider rules="max:200" v-slot="{ errors }" name="company_personal_id">
                <v-text-field
                  v-model="editedItem.company_id"
                  name="company_id"
                  clearable
                  counter="200"
                  :label="$t('clientsPage.companyId')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider rules="required" v-slot="{ errors }" name="client_type">
                <v-autocomplete
                  v-model="editedItem.type"
                  clearable
                  :items="clientTypes"
                  item-text="name"
                  item-value="value"
                  :label="$t('clientsPage.clientType')"
                  name="client_type"
                  :error-messages="errors"
                  :no-data-text="$t('select.noDataAvailable')"
                  @focus="$event.target.click()"></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <validation-provider
                rules="required|numeric_decimal|max_value:100|max:200"
                v-slot="{ errors }"
                name="percentage">
                <v-text-field
                  v-model="editedItem.percentage"
                  name="percentage"
                  clearable
                  counter="200"
                  :label="$t('clientsPage.percentage')"
                  :error-messages="errors"></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
      </v-card-actions>
    </v-form>
  </validation-observer>
</template>

<script>
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import i18n from '@/i18n/i18n';

export default {
  name: 'ClientsForm',
  components: { ButtonSubmit },
  props: ['editedItem'],
  data: () => ({
    clientTypes: [
      { value: 'natural_person', name: i18n.t('clientsPage.individual') },
      { value: 'legal_entity', name: i18n.t('clientsPage.business') },
    ],
    loading: false,
  }),
  created() {},
  methods: {
    async save() {
      this.loading = true;
      let formData = this.createFormData();

      await this.$store
        .dispatch('clients/saveClient', formData)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.$store.dispatch('clients/fetchAllClients');
          this.$emit('closeClientEdit');
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    createFormData() {
      let formData = new FormData();
      if (this.editedItem.id) {
        formData.append('_method', 'POST');
        formData.append('id', this.editedItem.id);
      } else {
        formData.append('id', '');
      }

      formData.append('name', this.editedItem.name ? this.editedItem.name : '');
      formData.append('address', this.editedItem.address ? this.editedItem.address : '');
      formData.append('phone', this.editedItem.phone ? this.editedItem.phone : '');
      formData.append('email', this.editedItem.email ? this.editedItem.email : '');
      formData.append('pib', this.editedItem.company_id ? this.editedItem.company_id : '');
      formData.append('type', this.editedItem.type ? this.editedItem.type : '');
      formData.append('percent', this.editedItem.percentage ? this.editedItem.percentage : 0);

      return formData;
    },

    resetForm() {
      this.$refs.form.reset();
    },
  },
};
</script>
